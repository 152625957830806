<template>
  <v-container id="crud" fluid tag="section">
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="showHeaders"
        :items="filteredData"
        :search="search"
        sort-by="created_at"
        sort-desc
        class="elevation-1"
      >
        <template v-slot:[`header.titulo`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.mensaje`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.leida`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`item.leida`]="{ item }">
          <div
            :class="$getCss(item.leida, 'estado_notificacion')"
            style="width: 100px"
            dark
          >
            {{ $getValue(item.leida, "estado_notificacion") }}
          </div>
        </template>

        <template v-slot:top>
          <v-toolbar flat color="white" class="pr-4">
            <v-row>
              <v-col sm="6" class="pt-20">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="'Busqueda de ' + title"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col sm="2"></v-col>
              <v-col sm="3" class="">
                <v-autocomplete
                  v-model="selectedHeaders"
                  :items="headers"
                  label="Columnas Visibles"
                  multiple
                  return-object
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 2">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span v-if="index === 2" class="grey--text caption"
                      >(otras {{ selectedHeaders.length - 2 }}+)</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="12" sm="12"> </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          <span>{{
            item.created_at ? new Date(item.created_at).toLocaleString() : ""
          }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-row class="">
            <v-col cols="6" md="6" sm="6" style="padding: 6px 0px 0px 0px">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="transparent elevation-0"
                    dark
                    small
                    v-bind="attrs"
                    v-on="on"
                    min-width="30px"
                  >
                    <v-icon
                      large
                      color="blue-grey lighten-3"
                      style="font-size: 30px"
                    >
                      mdi-dialpad
                    </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="showNotificacion(item)">
                    <v-list-item-icon class="mr-0"
                      ><v-icon
                        small
                        class="mr-2"
                        :color="$cv('btnVista')"
                        title="Ver"
                      >
                        mdi-eye-outline
                      </v-icon> </v-list-item-icon
                    ><v-list-item-content>
                      <v-list-item-title>Ver</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="item.leida == '0'"
                    @click="changeNotificationStatus(item, 1)"
                  >
                    <v-list-item-icon class="mr-0">
                      <v-icon
                        medium
                        class="mr-2"
                        color="green"
                        title="Marcar como leída"
                      >
                        mdi-email-open
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Marcar como leída</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="item.leida == '1'"
                    @click="changeNotificationStatus(item, 0)"
                  >
                    <v-list-item-icon class="mr-0">
                      <v-icon
                        medium
                        class="mr-2"
                        color="red"
                        title="Marcar como no leída"
                      >
                        mdi-email
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >Marcar como no leída</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
function title() {
  return "Notificaciones";
}

export default {
  data: (vm) => ({
    valid: true,
    rows: 1,
    title: title(),
    dessertActivo: "",
    route: "notificacionesUsuarios",
    notificacionesChangeStatus: "notificacionesChangeStatus",
    titlePerms: title().toLowerCase(),
    menu: false,
    modal: false,
    menu2: false,
    dialog: false,
    showDialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    rules: [(v) => v.length <= 500 || "Max 500 caracteres"],
    search: "",
    vista: false,
    headers: [
      { text: "Acciones", value: "actions", sortable: false },
      { text: "ID", value: "id" },
      { text: "Titulo", value: "titulo" },
      { text: "Mensaje", value: "mensaje" },
      { text: "Leida", value: "leida" },
      { text: "Creado", value: "created_at" },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    filters: {
      titulo: "",
      mensaje: "",
      leida: "",
    },
    filterKey: [],
    selectedHeaders: [],
    itemsSN: [
      { id: true, detalle: "SI" },
      { id: false, detalle: "NO" },
    ],
    estados: [
      { id: 1, detalle: "PENDIENTE" },
      { id: 2, detalle: "APROBADA" },
      { id: 3, detalle: "RECHAZADA" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : this.vista == false
        ? "Editar " + this.title
        : this.title;
    },
    filteredData() {
      return this.$filteredData().data !== undefined
        ? this.$filteredData().data
        : this.$filteredData();
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },

  watch: {
    async dialog(val) {
      if (!val) {
        this.$close();
      }
    },
  },

  created() {
    this.initialize();
    this.selectedHeaders = this.headers;
  },

  methods: {
    showNotificacion(item) {
      //console.log(item);
      Swal.fire({
        title: "Título: " + item.titulo,
        icon: "info",
        html:
          "Mensaje: " +
          "<b>" +
          item.mensaje +
          "</b>" +
          " <br> Fecha: <b>" +
          new Date(item.created_at).toLocaleString() +
          "</b>",
        confirmButtonText: "Aceptar",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //console.log(item);
          //marco como leida la notificacion
          this.changeNotificationStatus(item, 1);
        }
      });
    },
    async changeNotificationStatus(item, estado) {
      let formData = {
        notificacion_id: item.id,
        usuario_id: this.$store.state.user.id,
        leida: estado,
      };
      this.$axiosApi.post(this.notificacionesChangeStatus, formData).then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.initialize();
            this.$getNotificaciones();
          } else {
            console.log(response);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async initialize() {
      let vm = this;
      let id = vm.$store.state.user.id;
      //aca toque
      Swal.alertGetInfo("Obteniendo información <br><b></b>");

      await vm.$axiosApi
        .getById(vm.route, id)
        .then((r) => {
          this.desserts = r.data.data;
          Swal.close();
          //vm.snackbar = false;
        })
        .catch(function (error) {
          Swal.close();
          Vue.prototype.$respuesta(vm, error, 3, true);
        });
    },
    async editItem(id) {
      let vm = this;
      //aca toque
      Swal.alertGetInfo("Obteniendo información <br><b></b>");

      await vm.$axiosApi
        .getById(vm.route, id)
        .then((r) => {
          this.editedItem = r.data.data;
          this.editedIndex = id;
          this.showDialog = true;
          this.dialog = true;
          //console.log(r.data.data);
          Swal.close();
          //vm.snackbar = false;
        })
        .catch(function (error) {
          Swal.close();
          Vue.prototype.$respuesta(vm, error, 3, true);
        });
    },
    getDetalle(id, seccion) {
      let options = Array();
      switch (seccion) {
        case "estado":
          options = this.estados;
          break;
      }
      console.log(options);
      if (id) {
        return options.find((o) => o.id == id).detalle;
      }
    },

    filterByTitulo(item) {
      return this.$filterBy(item, "titulo");
    },
    filterByMensaje(item) {
      return this.$filterBy(item, "mensaje");
    },
    filterByLeida(item) {
      return this.$filterBy(item, "leida");
    },
  },
  mounted() {
    console.log("Componente " + this.route + " creado");
  },
};
</script>
<style scoped>
.text-fields-row {
  display: flex;
}
.x-btn-grid {
  flex: unset;
  background-color: bisque;
  margin: 0 0px;
}

/* Ajustar z-index de SweetAlert2 */
.swal2-container {
  z-index: 999999 !important;
}
.swal2-content {
  z-index: 999999 !important;
}
</style>
